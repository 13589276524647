import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import { ListItemComp } from '@/components/listItem';
import PageLinkComponent from '@/components/page/linkComponent';
import { useGraphQL } from '@/data';
import { useGraphqlResult } from '@/data/query/graphqlProvider';
import {
	GatewaysPublicRead_StorePaymentQuery,
	GatewaysPublicRead_StorePaymentQueryVariables,
} from '@/generated/graphql';
import currencyFormat from '@/helpers/currencyFormat';
import { convertWordIntoLink } from '@/helpers/linkFormat';
import SyncToClover from '@/helpers/syncToClover';
import useAccountType from '@/helpers/useAccountType';
import isOrderSyncedToClover from '@/helpers/useCheckIsSyncedToClover';
import { useCompany } from '@/hooks/useSetCompanyInAtom';
import ConnectVoPayFormModal from '@/modals/vopayForm/connectVopayFormModal';
import { stayOpen } from '@/pages/dashboard/commerce/components/tableHelpers';
import { clientPaymentsReadGQL } from '@/pages/dashboard/commerce/multiPayments/utils';
import { getPayingTotalAfterCashDiscount } from '@/pages/dashboard/commerce/payment/helpers';
import CardConnectFormModal from '@/pages/dashboard/settings/gateway/cardConnectForm/index';
import { GatewaysPublicRead_StorePayment } from '@/pages/p/store/payment/utils';
import { fetchCards, surchargeFeeAtom } from '@/pages/settings/cards';
import usePermissions from '@/providers/auth/usePermissions';
import { useModal, useModalControls } from '@/providers/modal';
import { ClientCredit, GatewayBase, Location, Order, Payment, QueryClientPaymentsReadArgs } from '@/types/schema';
import { ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material';
import {
	Box,
	Button,
	Chip,
	Collapse,
	Fade,
	FormControlLabel,
	Paper,
	Radio,
	RadioGroup,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useAtomValue } from 'jotai/index';
import { isEmpty, isString, lowerCase, round, toLower } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';
import { useAsyncEffect } from 'rooks';
import { updateStock } from '../orders/utils';
import CardTypeOptions from './cardType';
import ClientPaymentSelection from './clientPayment';
import PaymentCredit from './credit';

export default function PaymentMethod( {
	required,
	selectedCredits,
	setSelectedCredits,
	selectedClientPayment,
	setSelectedClientPayment,
	cardType,
	setCardType,
	cancelMethod,
	confirmMethod,
}: {
	required?: boolean,
	selectedCredits?: ClientCredit[],
	setSelectedCredits?: ( credits: ClientCredit[] ) => void,
	selectedClientPayment?: Payment,
	setSelectedClientPayment?: ( payment: Payment ) => void,
	cardType?: string,
	setCardType?: ( cardType: string ) => void,
	cancelMethod?: () => void,
	confirmMethod?: ( methodObject: any ) => Promise<void>
} ) {
	const { enqueueSnackbar } = useSnackbar();
	const { showModal } = useModal();
	
	const {
		      gateway: _gateway,
		      companyLocation,
		      company,
		      client,
		      type,
		      metadata,
		      paidTotal,
		      grandTotal = 0,
		      id,
		      externalId,
		      externalValue,
	      } = useGraphqlResult<Order>();
	const commerce = useGraphqlResult<Order>();
	const queryClient = useQueryClient();
	const { closeModal } = useModalControls();
	const router = useRouter();
	const { company: sessionCompany } = useCompany();
	
	const isCloverAccount = useAccountType( 'CLOVER', company );
	
	const clientPage = router.route.split( '/' )[ 1 ] === 'p' || router.route.split( '/' )[ 1 ] === 'client';
	const [ paymentMethod, setPaymentMethod ] = useState( '' );
	const [ creditDiscount, setCreditDiscount ] = useState( 0 );
	const [ cardId, setCardId ] = useState( '' );
	const [ customerID, setCustomerID ] = useState( '' );
	const [ paymentGateway, setPaymentGateway ] = useState<GatewayBase | null>( null );
	const [ cards, setCards ] = useState( [] );
	
	const gateway = _gateway || companyLocation?.gateway || company.mainPayment;
	const isOwner = usePermissions( [ 'OWNER' ] );
	const { t } = useTranslation();
	
	const voPayGateway = sessionCompany?.gateways?.find( ( g ) => g.external === 'VOPAY' );
	const caAddress = sessionCompany?.locations
		?.map( ( l ) => l.address )
		?.find( ( addr ) => [ 'canada', 'ca' ].includes( lowerCase( addr.country ) ) );
	
	const noCardConnectGateway = sessionCompany?.gateways?.find( ( g ) => g.external === 'CARD_CONNECT' );
	const usaAddress = sessionCompany?.locations
		?.map( ( l ) => l.address )
		?.find( ( addr ) => [ 'united states', 'us', 'usa' ].includes( lowerCase( addr.country ) ) );
	
	const syncedToClover = isOrderSyncedToClover( {
		type,
		gateway: { external: gateway?.external },
		externalId,
	} as Order );
	
	const { data } = useGraphQL<GatewaysPublicRead_StorePaymentQueryVariables, GatewaysPublicRead_StorePaymentQuery>( {
		query    : GatewaysPublicRead_StorePayment,
		queryKey : [ 'gatewaysRead' ],
		variables: { options: { limit: 50, filter: { active: true, company: company.id } } },
	}, { keepPreviousData: true, enabled: Boolean( company.id ) } );
	
	const { data: paymentData } = useGraphQL<QueryClientPaymentsReadArgs>( {
		query    : clientPaymentsReadGQL,
		queryKey : [ 'clientPaymentsRead' ],
		variables: {
			options: {
				limit : 100,
				filter: { client: client?.id, order: null, status: 'OPEN', amount: { $gt: 0 } },
			},
		},
	}, { keepPreviousData: true, enabled: Boolean( client?.id ) } );
	
	const gatewayData = data?.gatewaysPublicRead;
	
	const clientsPayments = paymentData?.clientPaymentsRead?.items;
	
	// vopay can do bank transfer for $1
	const vopayOrder = gateway && gateway.external === 'VOPAY';
	const cardConnectOrder = gateway && gateway.external === 'CARD_CONNECT';
	const stripeOrder = gateway && gateway?.external === 'STRIPE';
	
	const cloverGateway = gatewayData?.find( ( gateway ) => gateway.external === 'CLOVER' );
	const defaultGateway = gatewayData?.find( ( gateway ) => gateway.id === company.mainPayment?.id );
	const defaultCardGateway = gatewayData?.find( ( gateway ) => gateway.external === defaultGateway?.external && gateway.paymentMethod.includes( 'CREDIT_CARD' ) );
	const defaultAchGateway = gatewayData?.find( ( gateway ) => gateway.external === defaultGateway?.external && gateway.paymentMethod.includes( 'ACH' ) && gateway.external !== 'CLOVER' );
	
	const cashDiscountPercent = paidTotal === 0 && metadata?.enableCashDiscount && ( company.metadata?.cashDiscount || 0 );
	const stripeAndClover = stripeOrder && cloverGateway;
	const finalRemainingAfterCashDiscount = cashDiscountPercent
		? getPayingTotalAfterCashDiscount( commerce, cashDiscountPercent ).orderGrandTotal
		: 0;
	
	useAsyncEffect( async () => {
		try {
			if ( !client?.externalId || companyLocation?.gateway?.external !== 'CLOVER' ) return;
			await fetchCards( client?.id, setCards );
		} catch {
		}
		
	}, [ client?.id, client?.externalId ] );
	
	const { data: tenderData } = useQuery( [ 'getTenders' ], async () => {
		const { data } = await axios.post( '/api/processor/payment/getTenders', {
			id: gateway?.id,
		} );
		return data;
	}, { enabled: Boolean( id ) && Boolean( cloverGateway ) && router.route.split( '/' )[ 1 ] !== 'p' } );
	
	const tenders = !isEmpty( tenderData?.elements ) ? tenderData.elements.filter( ( tender ) => {
		const loweredLabel = toLower( tender.label );
		return tender.visible
			&& tender.enabled
			&& ![
				'credit card',
				'card',
				'cash',
				'check',
				'debit card',
				'select customer',
				'invoice credit',
				'house account',
				'ach',
			].includes( loweredLabel );
	} ) : undefined;
	
	useEffect( () => {
		if ( paymentMethod !== 'House Account' ) {
			setCustomerID( '' );
		}
	}, [ paymentMethod ] );
	
	const includeDebitCardFee = company.metadata?.includeDebitCardFee;
	
	const unUsedCredits = !required ? client?.clientCredits?.filter( ( credit ) => !credit.selected ) : [];
	const unUsedPrepaidCredits = required
		? client?.clientCredits?.filter( ( credit ) => credit.amount && credit.prepaid )
		: [];
	
	const surchargeFeePercent = useAtomValue( surchargeFeeAtom );
	const cardFeePercent = company.metadata?.cardFee;
	const stripePaymentMethods = gateway?.external === 'STRIPE' ? gateway?.paymentMethod : null;
	const stripeHasSingleMethod = !cloverGateway && stripeOrder && stripePaymentMethods?.length === 1;
	const isStripeNoCloverAndOnlyACH = stripeHasSingleMethod && stripePaymentMethods?.[ 0 ] === 'ACH';
	const stripeHasBothMethods = !cloverGateway && stripeOrder && stripePaymentMethods?.includes( 'ACH' ) && stripePaymentMethods?.includes( 'CREDIT_CARD' );
	const showCardType = !surchargeFeePercent && ( isStripeNoCloverAndOnlyACH || includeDebitCardFee || commerce.metadata.cardFee )
		? false
		: ( surchargeFeePercent || cardFeePercent ) && paymentMethod === 'card' && !metadata?.enableCardFee;
	const cloverCreditCardNotAllowed = cloverGateway && !cloverGateway.paymentMethod?.includes( 'CREDIT_CARD' );
	const typeAccountNotSynced = type === 'ACCOUNT' && !externalId && gateway?.external === 'CLOVER';
	const isCloverAndNotSynced = isCloverAccount && !syncedToClover && !externalValue && !stripeOrder && !typeAccountNotSynced;
	const noVoPayAndCaAddress = !voPayGateway && !!caAddress && !clientPage;
	const noCardConnectAndUsaAddress = !noCardConnectGateway && !!usaAddress && !clientPage;
	
	const hasCardConnect = sessionCompany?.gateways?.some( ( g ) => g.external === 'CARD_CONNECT' );
	const hasUsdGateway = sessionCompany?.gateways?.some( ( g ) => g.currency === 'USD' );
	const hasAchGateway = sessionCompany?.gateways?.some( ( gateway ) => gateway.paymentMethod?.includes( 'ACH' ) );
	const hasUsAddress = sessionCompany?.locations
		?.map( ( l ) => l.address )
		?.some( ( addr ) => [ 'united states', 'us', 'usa' ].includes( lowerCase( addr.country ) ) );
	const showCardConnect = !clientPage && !hasAchGateway && !hasCardConnect && ( hasUsAddress || hasUsdGateway );
	
	return (
		<Fragment>
			<TransitionGroup>
				<RadioGroup
					sx={{
						'.MuiFormControlLabel-root': {
							'width'       : 'max-content',
							'pr'          : 1.5,
							'borderRadius': 2,
							'transition'  : '.2s all',
							':hover'      : {
								bgcolor: 'divider',
							},
						},
					}}
					value={paymentMethod}
					onChange={( e ) => {
						setCardType?.( '' );
						setSelectedClientPayment?.( null );
						setSelectedCredits?.( [] );
						if ( e.target.value && e.target.value.includes( 'saved' ) ) {
							const cardToken = e.target.value.split( '-' )?.[ 1 ];
							if ( cardToken ) {
								setCardId( cardToken );
							}
						}
						setPaymentMethod( e.target.value );
						if ( e.target.value === 'ach' ) {
							if ( !defaultAchGateway ) {
								enqueueSnackbar( `If you need bank transfer, please contact Invoiss support. ${required
									? 'Contact the merchant.'
									: ''}`, { variant: 'info' } );
								return;
							}
							setPaymentGateway( defaultAchGateway );
						} else if ( e.target.value === 'card' && !isCloverAccount && defaultCardGateway ) {
							setPaymentGateway( defaultCardGateway );
						} else {
							// if Card Connect is the only and the main payment, then process without a gateway
							if ( defaultGateway?.external === 'CARD_CONNECT' && !cloverGateway ) {
								setPaymentGateway( null );
							} else {
								setPaymentGateway( gateway || isCloverAccount && !syncedToClover && externalValue && cloverGateway );
							}
							
						}
					}}>
					<Stack alignItems='start'>
						{!cloverCreditCardNotAllowed && client && !isEmpty( cards ) && cards.map( ( card: any, index ) => (
							<Fade key={index} in timeout={900}>
								<Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'start', sm: 'center' }}>
									<FormControlLabel
										key={card.id}
										value={`saved-${card.token}`}
										control={<Radio/>}
										disabled={clientPage && metadata?.disablePayment || !syncedToClover && !stripeOrder && !typeAccountNotSynced}
										label={(
											<Fragment>
												Saved Card {card.last4 ? `(**** ${card.last4}) ${card?.expirationDate
													? `Exp: ${card.expirationDate?.substring( 0, 2 )}/${card.expirationDate.substring( 2 )}`
													: ''}` : ''}
												{surchargeFeePercent || cardFeePercent && !metadata?.enableCardFee
													? ` (${round( surchargeFeePercent || cardFeePercent, 2 )}% Charge)`
													: ''}
											</Fragment>
										)}
										onClick={() => setCardId( card?.token )}
									/>
									<AsyncLoadingButton
										color='error'
										variant='text'
										onClick={async () => {
											const { data } = await axios.post( '/api/processor/payment/deleteCard', {
												id      : client.id,
												cardId  : card.id,
												sourceId: card.token,
											} );
											enqueueSnackbar( isString( data )
												? data
												: t( 'common:card-remove-success' ), { variant: 'success' } );
											await fetchCards( client.id, setCards );
										}}>
										Remove
									</AsyncLoadingButton>
								</Stack>
							</Fade>
						) )}
						{!typeAccountNotSynced && isStripeNoCloverAndOnlyACH && stripeAndClover || cloverCreditCardNotAllowed
							? null
							: gateway?.active && ( gateway.external === 'CLOVER' || gateway.paymentMethod.includes( 'CREDIT_CARD' ) )
								? (
									<Fade in timeout={900}>
										<Box>
											<Stack
												direction={{ xs: 'column', sm: 'row' }}
												alignItems={{ xs: 'start', sm: 'center' }}>
												<FormControlLabel
													value='card'
													control={<Radio/>}
													disabled={clientPage && metadata?.disablePayment
														|| isCloverAccount && ( !syncedToClover && ( !externalValue && !typeAccountNotSynced ) )
														|| !syncedToClover && !stripeOrder && ( isCloverAccount && ( !externalValue && !typeAccountNotSynced ) )}
													label={`${t( 'common:card' )} ${surchargeFeePercent || cardFeePercent && includeDebitCardFee
														? `(${round( surchargeFeePercent || cardFeePercent, 2 )}% Charge)`
														: ''} ${isCloverAndNotSynced && !clientPage
														? `- ${t( 'common:please-sync' )} ${toLower( type )} ${t( 'common:please-sync-post' )}`
														: ''}`}
												/>
												{isCloverAndNotSynced && !clientPage && (
													<AsyncLoadingButton
														variant='text'
														color='primary'
														onClick={async () => {
															try {
																await SyncToClover( commerce );
																await updateStock( commerce, company, 'manualSyncStock' );
																await queryClient.invalidateQueries( [ 'order' ] );
																enqueueSnackbar( 'Synced successfully.', { variant: 'success' } );
															} catch ( e ) {
																const cloverErrors = e?.response.data?.cloverErrors;
																if ( cloverErrors ) {
																	throw isString( cloverErrors )
																		? cloverErrors
																		: cloverErrors?.error?.message || cloverErrors?.message || t( 'common:error-clover' );
																} else {
																	enqueueSnackbar( t( 'common:sync-error' ), { variant: 'default' } );
																}
																
																console.error( e );
																throw e;
															}
															closeModal();
														}}>
														{t( 'common:sync' )}
													</AsyncLoadingButton>
												)}
											</Stack>
											<Collapse unmountOnExit in={Boolean( showCardType )}>
												<CardTypeOptions
													cardType={cardType}
													setCardType={setCardType}
													cardFeePercent={surchargeFeePercent || cardFeePercent}
												/>
											</Collapse>
										</Box>
									</Fade>
								)
								: null}
						{( isStripeNoCloverAndOnlyACH || stripeHasBothMethods || defaultAchGateway || noVoPayAndCaAddress || noCardConnectAndUsaAddress ) && !defaultAchGateway?.achDisabled && !metadata?.disableBankTransfer && (
							<Fade in timeout={900}>
								<Box>
									<Stack
										direction={{ xs: 'column', sm: 'row' }}
										alignItems={{ xs: 'start', sm: 'center' }}>
										<FormControlLabel
											value='ach'
											disabled={!( defaultAchGateway || stripeOrder || vopayOrder || cardConnectOrder )}
											control={<Radio/>}
											label={`${!vopayOrder && grandTotal < 2 && !caAddress
												? t( 'common:bank-transfer-pre' )
												: t( 'common:bank-transfer' )}${cashDiscountPercent
												? ` (${cashDiscountPercent}% Discount)`
												: ''}`}
										/>
										{noVoPayAndCaAddress && !clientPage && (
											<AsyncLoadingButton
												variant='text'
												color='primary'
												onClick={() => {
													showModal( ConnectVoPayFormModal, {
														id      : 'vopay-connect-modal',
														onClose : ( event, reason ) => stayOpen( event, reason ),
														maxWidth: 'xs',
													} );
												}}>
												Connect Bank
											</AsyncLoadingButton>
										)}
										{showCardConnect && (
											<AsyncLoadingButton
												variant='text'
												color='primary'
												onClick={() => {
													showModal( CardConnectFormModal, {
														id           : 'card-connect-connect-modal',
														onClose      : ( event, reason ) => stayOpen( event, reason ),
														maxWidth     : 'xs',
														fullPageModal: true,
														sx           : {
															'.MuiDialog-paper': {
																overflow: 'hidden !important',
															},
														},
													} );
												}}>
												Connect Bank
											</AsyncLoadingButton>
										)}
									</Stack>
								</Box>
							</Fade>
						)}
						{!required && (
							<Fade in timeout={900}>
								<FormControlLabel
									value='cash'
									control={<Radio/>}
									label={cashDiscountPercent > 0
										? t( 'commerce:cash-discount', { cashDiscount: cashDiscountPercent } )
										: t( 'common:cash' )}
								/>
							</Fade>
						)}
						{!required && (
							<Fade in timeout={900}>
								<FormControlLabel
									value='check'
									control={<Radio/>}
									label={cashDiscountPercent > 0
										? t( 'commerce:check-discount', { cashDiscount: cashDiscountPercent } )
										: t( 'common:check' )}
								/>
							</Fade>
						)}
						{!isEmpty( required ? unUsedPrepaidCredits : unUsedCredits ) && (
							<Fade in timeout={900}>
								<Stack spacing={1}>
									<Stack direction='row' alignItems='center'>
										<FormControlLabel
											value='Invoice Credit'
											control={<Radio/>}
											label={t( 'common:credit' )}
										/>
										<Tooltip
											placement='right'
											title='Apply credit amount as Credit tender.'>
											<Chip
												label={t( 'commerce:credit' )}
												color='primary'
												variant='alpha'
											/>
										</Tooltip>
									</Stack>
									{paymentMethod === 'Invoice Credit' && (
										<PaymentCredit
											selectedCredits={selectedCredits}
											setSelectedCredits={setSelectedCredits}
											setCreditDiscount={setCreditDiscount}
											unUsedCredits={required ? unUsedPrepaidCredits : unUsedCredits}
											orderTotal={grandTotal - ( paidTotal || 0 )}
										/>
									)}
								</Stack>
							</Fade>
						)}
						{!required && !isEmpty( clientsPayments ) && (
							<Fade in timeout={900}>
								<Box>
									<Stack direction='row' alignItems='center'>
										<FormControlLabel
											value='Previous payments'
											control={<Radio/>}
											label={t( 'common:payment' )}
										/>
										<Tooltip
											placement='right'
											title='Pay with unapplied payments'>
											<Chip
												label='Unallocated payments'
												color='primary'
												variant='alpha'
											/>
										</Tooltip>
									</Stack>
									<Collapse unmountOnExit in={Boolean( paymentMethod === 'Previous payments' )}>
										<ClientPaymentSelection
											clientPayments={clientsPayments}
											selectedClientPayment={selectedClientPayment}
											setSelectedClientPayment={setSelectedClientPayment}
										/>
									</Collapse>
								</Box>
							</Fade>
						)}
						{company.metadata?.offlineACH?.instructions?.length > 0 && (
							<Fade in timeout={900}>
								<FormControlLabel
									value='offlineACH'
									control={<Radio/>}
									label={`${cashDiscountPercent > 0
										? t( 'commerce:offline-ach-discount', { cashDiscount: cashDiscountPercent } )
										: t( 'common:offline-ach' )}`}
								/>
							</Fade>
						)}
						<Collapse
							in={Boolean( paymentMethod === 'offlineACH' && company.metadata?.offlineACH?.instructions?.length > 1 )}
							sx={{ width: '100%' }}>
							<Paper sx={{ p: 2, my: 1, maxHeight: 200, overflow: 'auto' }}>
								<Typography gutterBottom sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
									Instructions:
								</Typography>
								<Typography sx={{ whiteSpace: 'pre-line', color: 'text.secondary' }}>
									{convertWordIntoLink( company.metadata?.offlineACH?.instructions, 'https' )}
								</Typography>
							</Paper>
						</Collapse>
						{!required && tenders && [ ...type !== 'INVOICE'
							? tenders.filter( ( tender ) => toLower( tender.label ) !== 'invoiss' )
							: tenders ].map( ( tender, index ) => (
							<Fade key={index} in timeout={900}>
									<Stack
									direction='row'
									alignItems='center'
									onClick={( e ) => e.stopPropagation()}>
									<FormControlLabel
										value={toLower( tender.label )}
										control={<Radio/>}
										label={`${cashDiscountPercent > 0 && toLower( tender?.label ) !== 'invoiss'
											? `${tender.label} (${cashDiscountPercent}% Discount)`
											: tender.label}`}
										/>
									<Tooltip
										placement='right'
										title={tender?.label === 'Invoiss'
											? t( 'commerce:use-invoiss-tender-to-charge' )
											: tender?.label
												? t( 'commerce:created-by-clover-dashboard' )
												: t( 'commerce:customer-tender' )}>
											<Chip
											label={tender?.label === 'Invoiss'
												? t( 'common:house-account' )
												: t( 'commerce:tender' )}
											color={tender?.label === 'Invoiss' ? 'primary' : 'default'}
											variant='alpha'
										/>
          </Tooltip>
								</Stack>
        </Fade>
						) )}
					</Stack>
				</RadioGroup>
			</TransitionGroup>
			{!required && syncedToClover && isOwner && (
				<Tooltip placement='right' title={t( 'common:add-tenders' )}>
					<Button
						variant='text'
						component={PageLinkComponent}
						href='https://www.clover.com/setupapp'
						target='_blank'>
						{t( 'commerce:add-more-tenders' )}
					</Button>
				</Tooltip>
			)}
			{required && clientPage && cashDiscountPercent > 0 && (
				<Paper>
					<Stack sx={{ p: 2 }} spacing={1}>
						<ListItemComp
							primary='Card amount:'
							tooltip=''
							rightComp={(
								<Typography variant='h5'>
									{currencyFormat( grandTotal )}
								</Typography>
							)}
							size='small'
						/>
						<ListItemComp
							primary={`Cash or Check amount: (${cashDiscountPercent}% discount)`}
							tooltip=''
							rightComp={(
								<Typography variant='h5' color='success.main'>
									{currencyFormat( finalRemainingAfterCashDiscount )}
								</Typography>
							)}
							size='small'
						/>
						<Typography color='warning.main' component='span' sx={{ pl: 2 }}>
							{t( 'commerce:cash-or-check-discount', { cashDiscount: cashDiscountPercent } )}
						</Typography>
					</Stack>
				</Paper>
			)}
			<Stack spacing={1} direction='row' alignItems='center' mt={2}>
				{typeof cancelMethod === 'function' && (
					<Button
						sx={{ mr: 1 }}
						variant='outlined'
						startIcon={<ArrowBackIosIcon/>}
						onClick={cancelMethod}>
						{t( 'common:back' )}
					</Button>
				)}
				<AsyncLoadingButton
					disabled={!paymentMethod || paymentMethod === 'offlineACH' || paymentMethod === 'Invoice Credit' && isEmpty( selectedCredits ) || showCardType && !cardType || paymentMethod === 'Previous payments' && !selectedClientPayment}
					variant='contained'
					color='primary'
					sx={{ width: 150 }}
					onClick={async () => {
						if ( paymentMethod !== 'House Account' ) {
							await confirmMethod?.( {
								paymentMethod,
								paymentGateway,
								cardId,
								creditAmount       : creditDiscount,
								clientPaymentAmount: selectedClientPayment?.amount,
							} );
						} else {
							if ( !customerID ) {
								enqueueSnackbar( t( 'common:house-number' ), { variant: 'error' } );
								return;
							}
							if ( customerID === client?.metadata?.companyNumber ) {
								await confirmMethod?.( { paymentMethod, paymentGateway, cardId } );
							} else {
								enqueueSnackbar( t( 'common:incorrect-house-number' ), { variant: 'error' } );
								return;
							}
						}
					}}>
					Continue
				</AsyncLoadingButton>
			</Stack>
		</Fragment>
	);
}

export function PaymentMethodLocation( {
	required,
	confirmMethod,
	paymentAmount,
	cardType,
	setCardType,
	setBackStep,
	prepayClientId,
}: {
	required: boolean,
	confirmMethod: any,
	paymentAmount: number,
	cardType: string,
	setCardType: ( cardType: string ) => void,
	setBackStep?: () => void,
	prepayClientId?: string
} ) {
	const { gateway, company } = useGraphqlResult<Location>();
	const { t } = useTranslation();
	const [ paymentMethod, setPaymentMethod ] = useState( '' );
	const [ paymentGateway, setPaymentGateway ] = useState<GatewayBase | null>( null );
	
	const { data } = useGraphQL<GatewaysPublicRead_StorePaymentQueryVariables, GatewaysPublicRead_StorePaymentQuery>( {
		query    : GatewaysPublicRead_StorePayment,
		queryKey : [ 'gatewaysRead' ],
		variables: { options: { limit: 50, filter: { active: true, company: company.id } } },
	}, { keepPreviousData: true, enabled: Boolean( company.id ) } );
	
	const gatewayData = data?.gatewaysPublicRead;
	
	const cloverGateway = gatewayData?.find( ( gateway ) => gateway.external === 'CLOVER' );
	const defaultGateway = gatewayData?.find( ( gateway ) => gateway.id === company.mainPayment?.id );
	const defaultCardGateway = gatewayData?.find( ( gateway ) => gateway.external === defaultGateway?.external && gateway.paymentMethod.includes( 'CREDIT_CARD' ) );
	const defaultAchGateway = gatewayData?.find( ( gateway ) => gateway.external === defaultGateway?.external && gateway.paymentMethod.includes( 'ACH' ) && gateway.external !== 'CLOVER' );
	
	const cardFeePercent = prepayClientId ? 0 : company.metadata?.cardFee;
	const includeDebitCardFee = company.metadata?.includeDebitCardFee;
	const stripeGatewayOnly = gateway?.external === 'STRIPE';
	
	// conditions to show card or bank transfer option
	const isCardPayment = prepayClientId || !stripeGatewayOnly && ( cloverGateway || defaultAchGateway );
	const cardLabel = t( 'common:card' );
	const bankTransferLabel = t( 'common:bank-transfer' );
	const feeLabel = cardFeePercent && isCardPayment ? ` (${includeDebitCardFee
		? ''
		: 'Credit Card Fee'} ${round( cardFeePercent, 2 )}% Charge)` : '';
	
	return (
		<Fragment>
			<RadioGroup
				value={paymentMethod}
				onChange={( e ) => {
					setPaymentMethod( e.target.value );
					if ( e.target.value === 'ach' && defaultAchGateway ) {
						setPaymentGateway( defaultAchGateway );
					} else if ( cloverGateway && gateway ) {
						setPaymentGateway( gateway );
					} else if ( defaultCardGateway ) {
						setPaymentGateway( defaultCardGateway );
					}
				}}>
				{( defaultAchGateway || stripeGatewayOnly || cloverGateway ) && gateway?.active || company.mainPayment?.active
					? (
						<Stack>
							<FormControlLabel
								value='card'
								control={<Radio/>}
								label={`${isCardPayment ? cardLabel : bankTransferLabel}${feeLabel}`}
							/>
							<Collapse in={Boolean( cardFeePercent && paymentMethod === 'card' && !includeDebitCardFee )}>
								<CardTypeOptions
									cardType={cardType}
									setCardType={setCardType}
									cardFeePercent={cardFeePercent}
								/>
							</Collapse>
						</Stack>
					)
					: null}
				{!prepayClientId && !stripeGatewayOnly && defaultAchGateway && cloverGateway && (
					<FormControlLabel
						value='ach'
						disabled={paymentAmount < 2}
						control={<Radio/>}
						label={`${paymentAmount < 2
							? t( 'common:bank-transfer-pre' )
							: t( 'common:bank-transfer' )}`}
					/>
				)}
				{!prepayClientId && !required && (
					<FormControlLabel
						value='cash'
						control={<Radio/>}
						label={t( 'common:cash' )}
					/>
				)}
				{!prepayClientId && !required && (
					<FormControlLabel
						value='check'
						control={<Radio/>}
						label={t( 'common:check' )}
					/>
				)}
			</RadioGroup>
			<Stack spacing={1} direction='row' alignItems='center' mt={2}>
				{typeof setBackStep === 'function' && (
					<Button
						variant='outlined'
						startIcon={<ArrowBackIosIcon/>}
						onClick={setBackStep}>
						{t( 'common:back' )}
					</Button>
				)}
				<AsyncLoadingButton
					disabled={!paymentMethod}
					variant='contained'
					color='primary'
					onClick={() => confirmMethod( paymentMethod, paymentGateway )}>
					{t( 'common:continue' )}
				</AsyncLoadingButton>
			</Stack>
		</Fragment>
	);
}
